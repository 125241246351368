import {
  BiChannel,
  Message,
  MessageRecombination,
  MessageType,
  splitMessage,
} from '@sqior/js/message';
import {
  addMinutes,
  addSeconds,
  Bytes,
  ClockTimestamp,
  StdTimer,
  TimerHolder,
  TimerInterface,
} from '@sqior/js/data';
import { IAuthContext } from '@sqior/js/authbase';
import { Emitter, StopListening } from '@sqior/js/event';
import { ConnectionState } from './connection-state';
import { WebSocketIF, MessageEvent, WebsocketCloseCode } from './websocketif';
import { Logger } from '@sqior/js/log';
import {
  AuthErrorCode,
  AuthErrorMessage,
  AuthRequestMessage,
  ConnectionMessageType,
  HandshakeInitMessage,
  HandshakeResponseCode,
  HandshakeResponseMessage,
  InternalMessage,
  PingMessage,
  isConnectionMessage,
} from '@sqior/js/session';

export interface HasConnectionState {
  state: ConnectionState;
  readonly stateChanged: Emitter<[ConnectionState]>;
  tryConnect(force: boolean): void;
}

export type ServerConnectionOptions = {
  authContext?: IAuthContext; // Authorization helper
  sessionId?: string; // Session ID
  pingInterval?: ClockTimestamp; // Interval in between pings
  pingTimeout?: ClockTimestamp; // Ping timeout
  disableAutoReconnect?: boolean; // Flag whether to disable automatic reconnection
  timer?: TimerInterface; // Customized timer to use
};

export abstract class ServerConnectionBase extends BiChannel implements HasConnectionState {
  constructor(url: string, version: string, options: ServerConnectionOptions = {}) {
    /* Initialize channels */
    super();

    Logger.trace('Constructing server connection');
    this.url = url;
    this.version = version;
    this.authContext = options.authContext;
    this.authContextTokenRefreshHandler = undefined;
    this.sessionIdRequested = options.sessionId;
    this.timer = options.timer ?? new StdTimer();

    this.pingInterval = options.pingInterval ?? addMinutes(1);
    this.pingTimeout = options.pingTimeout ?? addSeconds(5);

    this._state = ConnectionState.NOT_CONNECTED;
    this.stateChanged = new Emitter<[ConnectionState]>();

    this._lastConnectTry = 0;
    this._nextConnectTry = 0;
    this.connectTryChanged = new Emitter<[number, number]>();

    this.conn = undefined;
    this.successiveConnectTries = 0;
    this._shallReconnect = !options.disableAutoReconnect;
    this.recombiner = new MessageRecombination();

    this._tryConnect();
  }

  override close(code?: WebsocketCloseCode) {
    /* Avoid that an already scheduled reconnect timer fires after close */
    this.reconnectTimer.reset();
    /* Set a flag so that no new reconnect timers are started */
    this.shallReconnect = false;
    /* Disconnect to make sure no callbacks are triggered anymore */
    this.disconnect(code);
  }

  get shallReconnect() {
    return this._shallReconnect;
  }

  private set shallReconnect(v: boolean) {
    this._shallReconnect = v;
  }

  get lastConnectTry() {
    return this._lastConnectTry;
  }

  private set lastConnectTry(t: number) {
    this._lastConnectTry = t;
    this.connectTryChanged.emit(this._lastConnectTry, this._nextConnectTry);
  }

  get nextConnectTry() {
    return this._nextConnectTry;
  }

  private set nextConnectTry(t: number) {
    this._nextConnectTry = t;
    this.connectTryChanged.emit(this._lastConnectTry, this._nextConnectTry);
  }

  get state() {
    return this._state;
  }

  private set state(state: ConnectionState) {
    this._state = state;
    this.stateChanged.emit(state);
  }

  get sessionId(): string | undefined {
    return this._sessionIdReceived;
  }

  private set sessionId(sessionId: string | undefined) {
    this._sessionIdReceived = sessionId;
  }

  abstract createWebSocket(url: string): WebSocketIF;

  public tryConnect(force = false) {
    /* Disconnect from the current connection, if the new connection shall be forced */
    if (force) this.disconnect();
    /* Reset connection timeout back-off */
    this.successiveConnectTries = 0;
    this._tryConnect();
  }

  private _tryConnect() {
    // Do not allow to call this a second time
    if (this.conn) {
      Logger.debug('Connection attempt skipped because another connection is still active');
      return;
    }

    Logger.debug(['Try to connect to:', this.url]);

    /* Create the connection */
    this.successiveConnectTries++;
    this.lastConnectTry = this.timer.now;
    this.nextConnectTry = 0;
    this.conn = this.createWebSocket(this.url);

    /* Connect to the events emitted when the connection is established or torn down */
    this.conn.onopen = () => {
      this.handleOpened();
    };
    this.conn.onclose = () => {
      this.handleConnectionClosed();
    };
    this.conn.onerror = () => {
      Logger.info('Connection was closed due to error');
      // After onerror always onclose is called
      // => everything is handled in onclose handler
      // => just keep record of the error state here
      this.state = ConnectionState.ERROR;
    };

    /* Start a timer that ensures that the connection opens within the specified period */
    this.safetyTimer.set(
      this.timer.schedule(() => {
        Logger.info('Connection opening timed out - disconnecting and trying again');
        this.disconnect();
      }, this.pingTimeout * 2)
    );
  }

  private handleOpened() {
    /* Stop safety timer */
    this.safetyTimer.reset();

    /* Initialize state */
    this.state = ConnectionState.CONNECTED_HANDSHAKE;
    Logger.debug('Connection is open - initiating handshake');

    if (this.conn === undefined)
      // Should never happen
      throw 'this.conn is null';

    // Connect to messages being received by the socket
    if (this.conn)
      this.conn.onmessage = (ev: MessageEvent) => {
        this.recvMessage(ev);
      };

    // Connect to messages being sent towards the server
    this.outListener = this.out.on(MessageType.All, (msg: Message) => {
      this.sendMessage(msg);
    });

    /* Start the handshake, this is asynchronous! */
    const abortController = (this.tokenCreation = new AbortController());
    this.initiateHandshake(abortController.signal);

    /* Start a timer that ensures that the token is generated within a certain amout of time */
    this.safetyTimer.set(
      this.timer.schedule(() => {
        Logger.info(
          'Connection authentication token generation timed out - disconnecting and trying again'
        );
        this.disconnect();
      }, this.pingTimeout * 2)
    );
  }

  private sendMessage(msg: Message) {
    const [meta, binaries] = splitMessage(msg);

    this.conn?.send(JSON.stringify(meta));
    for (const bin of binaries) this.conn?.send(bin.buffer);
  }

  private recvMessage(ev: MessageEvent) {
    try {
      // Get message pieces from socket => feed to recombiner => if final, do further message processing
      const msg = this.recombiner.handle(
        typeof ev.data === 'string' ? JSON.parse(ev.data) : new Bytes(ev.data)
      );
      if (msg) {
        if (isConnectionMessage(msg)) {
          this.handleInternalMessage(msg);
        } else {
          this.in.send(msg);
        }
      }
    } catch (e) {
      Logger.warn(
        ['Error in parsing message from socket, skipping message:', Logger.exception(e)],
        [
          'Error in parsing message:',
          JSON.stringify(ev),
          'from socket, skipping message:',
          Logger.exception(e),
        ]
      );
    }
  }

  private async initiateHandshake(abortSignal: AbortSignal) {
    let msg: HandshakeInitMessage = {
      type: ConnectionMessageType.HandshakeInit,
      version: this.version,
      sessionIdentifier: this.sessionIdRequested,
    };

    if (this.authContext) {
      try {
        /* Generate token */
        const tokenRes = await this.authContext?.generateToken('dummy');
        msg = {
          ...msg,
          authToken: tokenRes.token,
        };
        if (tokenRes.subUserId) msg.subUserId = tokenRes.subUserId;
        if (tokenRes.sessionStart) msg.sessionStart = tokenRes.sessionStart;
      } catch (e) {
        // Error when refreshing token, pot. source of errors because it could be that this is caused by backgrounding the app
        if (abortSignal.aborted) {
          // Do not handle if the connection was anyway closed in the meantime
          Logger.info([
            'Authentication token creation failed with connection already closed - ignoring:',
            Logger.exception(e),
          ]);
          return;
        }
        Logger.info([
          'Exception during generation of authentication token - exception:',
          Logger.exception(e),
        ]);
        this.state = ConnectionState.ERROR_AUTH_FAILED;
        this.conn?.close();
        return;
      }
    }

    // Do not send if the connection was closed in the meantime
    if (abortSignal.aborted) {
      Logger.debug(
        'Authentication token creation succeeded with connection already closed - ignoring'
      );
      return;
    }
    /* Stop safety timer */
    this.safetyTimer.reset();

    /* Send token message */
    Logger.debug('Authentication token creation succeeded - sending to server');
    this.sendMessage(msg);

    /* Start a timer that ensures that the handshake completes within a certain amout of time */
    this.safetyTimer.set(
      this.timer.schedule(() => {
        Logger.info('Connection handshake timed out - disconnecting and trying again');
        this.disconnect();
      }, this.pingTimeout * 2)
    );
  }

  protected handleInternalMessage(msg: InternalMessage) {
    if (msg.type === ConnectionMessageType.HandshakeResponse) {
      const msgHS = <HandshakeResponseMessage>msg;
      if (msgHS.response === HandshakeResponseCode.OK) {
        Logger.info(['Connection succeeded:', msgHS.sessionIdentifier]);
        /* Stop safety timer */
        this.safetyTimer.reset();
        this.handleHandshakeSucceeded();
        this.sessionId = msgHS.sessionIdentifier;
        /* Set the received session ID as the one that will also be requested during the next connect */
        this.sessionIdRequested = this.sessionId;
        this.state = ConnectionState.CONNECTED;

        this.startPing();
      } else if (msgHS.response === HandshakeResponseCode.AuthenticationError) {
        Logger.info(
          msgHS.error === AuthErrorCode.SessionExpired
            ? 'Connection failed due to expiration of the internal session timeout'
            : 'Connection failed due to invalid token or expired user session'
        );
        this.state =
          msgHS.error === AuthErrorCode.SessionExpired ||
          msgHS.error === AuthErrorCode.InvalidCredentials
            ? ConnectionState.ERROR_AUTH_RESET
            : ConnectionState.ERROR_AUTH_FAILED;
        this.disconnect();
      } else if (msgHS.response === HandshakeResponseCode.CompatibilityError) {
        Logger.info('Connection failed due to incompatible versions');
        this.state = ConnectionState.ERROR_INCOMPATIBLE;
        this.disconnect();
      } else {
        Logger.warn(['Connection failed with unknown failure code', msgHS.response]);
        this.state = ConnectionState.ERROR;
        this.disconnect();
      }
    } else if (msg.type === ConnectionMessageType.AuthError) {
      const errorMsg = <AuthErrorMessage>msg;
      Logger.info(
        errorMsg.error === AuthErrorCode.SessionExpired
          ? 'Connection closed with authentication error - internal session timeout expired'
          : 'Connection closed with authentication error, likely an expired token'
      );
      this.state =
        errorMsg.error === AuthErrorCode.SessionExpired
          ? ConnectionState.ERROR_AUTH_RESET
          : ConnectionState.ERROR_AUTH_EXPIRED;
      this.disconnect();
    } else if (msg.type === ConnectionMessageType.PingResponse) {
      /* Check if the content is replayed correctly, if yes, start new ping cycle */
      if (this.lastConfirmedPing++ !== (<PingMessage>msg).identifier) {
        console.log(msg);
        console.log(this.lastConfirmedPing);
        Logger.warn('Ping response does not match emitted data - disconnecting and trying again');
        this.disconnect();
      } else if (this.lastConfirmedPing === this.lastSentPing)
        this.safetyTimer.set(this.timer.schedule(() => this.sendPing(), this.pingInterval));
    }
  }

  private handleHandshakeSucceeded() {
    /* Reset the retry count */
    this.successiveConnectTries = 0;
    this.connectChannels();

    if (this.authContext) {
      this.authContextTokenRefreshHandler = this.authContext.tokenRefreshed.on((token) => {
        const msg: AuthRequestMessage = {
          type: ConnectionMessageType.AuthRequest,
          authToken: token,
        };
        this.sendMessage(msg);
      });
    }
  }

  private handleConnectionClosed() {
    /* Reset token refresh handler if applicable */
    if (this.authContextTokenRefreshHandler) {
      this.authContextTokenRefreshHandler();
      this.authContextTokenRefreshHandler = undefined;
    }
    /* Signal a pot. token generation that this is already closed */
    if (this.tokenCreation) {
      this.tokenCreation.abort();
      this.tokenCreation = undefined;
    }
    /* Stop the running safety timer */
    this.safetyTimer.reset();

    // Close channels
    this.disconnectChannels();
    Logger.debug('Connection was closed, cleaning up'); // log after disconnecting channels as otherwise the log is attempted to be transferred

    // Disconnect from outgoing channel
    if (this.outListener) this.outListener();

    // Set state to NOT_CONNECTED if the previous state was CONNECTED (i.e. there wasn't an error)
    if (
      this.state === ConnectionState.CONNECTED ||
      this.state === ConnectionState.CONNECTED_HANDSHAKE
    ) {
      this.state = ConnectionState.NOT_CONNECTED;
    }

    this.cleanUpConnection();

    /* Try to reconnect except for the case if authentication failed because this cannot be resolved on this level */
    if (
      this.state !== ConnectionState.ERROR_AUTH_FAILED &&
      this.state !== ConnectionState.ERROR_AUTH_RESET &&
      this.state !== ConnectionState.ERROR_INCOMPATIBLE
    )
      this.tryToReconnect();
  }

  private connectChannels() {
    this.out.open();
    this.in.open();
  }

  private disconnectChannels() {
    this.out.close();
    this.in.close();
  }

  private cleanUpConnection() {
    this.recombiner.reset();
    if (!this.conn) return;
    this.conn.onopen = undefined;
    this.conn.onclose = undefined;
    this.conn.onerror = undefined;
    this.conn.onmessage = undefined;
    this.conn = undefined;
  }

  private tryToReconnect() {
    if (!this.shallReconnect) return;

    /* Increase the waiting with the number of unsuccessful attempts */
    let waitingTime = addSeconds(0);
    if (this.successiveConnectTries > 40) waitingTime = addSeconds(60);
    else if (this.successiveConnectTries > 20) waitingTime = addSeconds(30);
    else if (this.successiveConnectTries > 10) waitingTime = addSeconds(10);
    else if (this.successiveConnectTries > 5) waitingTime = addSeconds(5);
    else if (this.successiveConnectTries > 1) waitingTime = addSeconds(1);

    /* Set timer for retry */
    Logger.trace(['Next automatic reconnection attempt in:', waitingTime / 1000, 'seconds']);
    this.nextConnectTry = this.timer.now + waitingTime;
    this.reconnectTimer.set(
      this.timer.schedule(() => {
        Logger.trace([
          'Performing automatic reconnection attempt after waiting for:',
          waitingTime / 1000,
          'seconds',
        ]);
        this._tryConnect();
      }, waitingTime)
    );
  }

  /** Resets the ping such that it is performed as soon as possible in order to validate a connection */
  resetPing() {
    if (this.state === ConnectionState.CONNECTED) {
      this.safetyTimer.reset();
      this.sendPing();
    }
  }

  private startPing() {
    this.lastSentPing = this.lastConfirmedPing = 0;
    this.sendPing();
  }

  private sendPing() {
    if (this.state !== ConnectionState.CONNECTED) return;
    /* Define next ping message */
    const ping: PingMessage = {
      type: ConnectionMessageType.PingRequest,
      identifier: this.lastSentPing++,
      timestamp: this.timer.now,
    };
    this.sendMessage(ping);
    /* Set timer to detect if ping is not responded to */
    this.safetyTimer.set(
      this.timer.schedule(() => {
        Logger.info('Ping timed out - disconnecting and trying again');
        this.disconnect();
      }, this.pingTimeout)
    );
  }

  disconnect(code?: WebsocketCloseCode) {
    /* Close the connection if not done already */
    if (this.conn)
      this.conn.close(
        code,
        code === WebsocketCloseCode.LogOut ? 'User logged out from session' : undefined
      );
    /* Indicate that this is in the state of error, preserve authentication error, if applicable */
    if (
      this.state !== ConnectionState.ERROR_AUTH_FAILED &&
      this.state !== ConnectionState.ERROR_AUTH_EXPIRED &&
      this.state !== ConnectionState.ERROR_AUTH_RESET &&
      this.state !== ConnectionState.ERROR_INCOMPATIBLE
    )
      this.state = ConnectionState.ERROR;
    /* Clean up the connection, do not wait for onclose event as this is sometimes missing on iOS when being put into background mode */
    this.handleConnectionClosed();
  }

  readonly url: string;
  private version: string;
  private authContext?: IAuthContext;
  private authContextTokenRefreshHandler?: () => void;
  private tokenCreation?: AbortController; // Abort controller for asynchronous token creation in a synchronous FW

  private sessionIdRequested?: string;
  private _sessionIdReceived?: string;
  private timer: TimerInterface;

  private conn: WebSocketIF | undefined;
  private outListener?: StopListening;
  private successiveConnectTries: number;
  private _shallReconnect: boolean;
  private reconnectTimer = new TimerHolder();
  private _state: ConnectionState;
  stateChanged: Emitter<[ConnectionState]>;
  private _lastConnectTry: number;
  private _nextConnectTry: number;
  connectTryChanged: Emitter<[number, number]>;

  private recombiner: MessageRecombination;

  private lastSentPing = 0;
  private lastConfirmedPing = 0;
  private readonly pingInterval: number;
  private readonly pingTimeout: number;
  private safetyTimer = new TimerHolder();
}
