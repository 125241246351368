/* Base type of an interface that bundles requirements on entities or other interfaces supported */

import { Entity } from '@sqior/js/entity';
import { EventHistoryLevel, ModelInterface, Models } from './models';
import { MappingInterface } from './mapping-interface';
import { ErrorReportingMode, Logger } from '@sqior/js/log';
import { ensureArray, isEqual } from '@sqior/js/data';

export type Interface = {
  type: string; // Type name
  requires?: string | string[]; // Optional list of alternative required types - if specified, the entity type needs to match at least one to fulfill the interface
  represents?: string | (string | string[])[]; // Optional list of types, the entity type need to be mappable to each specified type to fulfill the interface
};

/* Typed interface model implementing the model interface */

export class TypedInterfaceModel<Type extends Entity = Entity> implements ModelInterface {
  constructor(model: Interface) {
    this.model = model;
  }

  /** Provides the type */
  get type() {
    return this.model.type;
  }
  /** Provides a possible base type */
  base = undefined;
  /** Checks if the type is keyable */
  keyable = false;
  /** Returns the anonymization level for event history */
  eventHistoryLevel = EventHistoryLevel.Expurgate;

  /** Provides a key */
  key(): string {
    throw new Error('Trying to determing key for interface type: ' + this.type);
  }

  /** Properties expected to be found in this object */
  properties(): Record<string, boolean> {
    throw new Error('Trying to determing properties for interface type: ' + this.type);
  }

  /** Validates an entity to be conformant as a mapping result of this type */
  validateResult(
    entity: Entity,
    models: Models,
    mapper: MappingInterface,
    mode?: ErrorReportingMode
  ): boolean {
    /* Check if the entity conforms to the expectations of the interface */
    if (
      this.model.requires &&
      !ensureArray(this.model.requires).find((r) => {
        return entity.entityType === r;
      })
    ) {
      Logger.reportError(
        [
          'Entity of type:',
          entity,
          'does not satisfy interface:',
          this.model.type,
          '- required:',
          this.model.requires,
        ],
        mode
      );
      return false;
    }
    if (this.model.represents)
      for (const rarr of ensureArray(this.model.represents))
        if (
          !ensureArray(rarr).find((r) => {
            return mapper.represents(entity, r);
          })
        ) {
          Logger.reportError(
            [
              'Entity of type:',
              entity.entityType,
              'does not satisfy interface:',
              this.type,
              '- cannot map to:',
              this.model.represents,
            ],
            mode
          );
          return false;
        }
    return true;
  }

  /** Validates the model */
  validateModel(models: Models) {
    /* Validate that only known concrete models are specified as requires */
    for (const req of ensureArray(this.model.requires)) {
      if (!models.has(req))
        throw new Error(
          'Interface model: ' + this.type + ' declares unknown requirement type: ' + req
        );
      try {
        models.properties(req);
      } catch (e) {
        throw new Error(
          'Interface model: ' + this.type + ' declares a requirement with an abstract type: ' + req
        );
      }
    }
    /* Validate that only known models are specified as represents */
    for (const repList of ensureArray(this.model.represents))
      for (const rep of ensureArray(repList))
        if (!models.has(rep))
          throw new Error(
            'Interface model: ' + this.type + ' declares unknown representation type: ' + rep
          );
  }

  /** Checks if the provided model is equal to this */
  isEqual(that: ModelInterface): boolean {
    return that instanceof TypedInterfaceModel && isEqual(this.model, that.model);
  }

  private model: Interface;
}
