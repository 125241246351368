import { Closable } from '@sqior/js/async';
import { Reevaluation } from '@sqior/js/cache';
import {
  ArraySource,
  ClockTimestamp,
  ensureArray,
  KeyPairMap,
  SortedArray,
  StdTimer,
  TimerInterface,
  ValueObject,
} from '@sqior/js/data';
import { CRUDInterface, DatabaseInterface, migrateCollection } from '@sqior/js/db';
import { Entity } from '@sqior/js/entity';
import { Logger, PerformanceMetric } from '@sqior/js/log';
import { ContextPropertyModel } from './context-property';
import { CoreEntities, CoreInterfaces } from './core-definitions';
import { DomainInterface } from './domain-interface';
import { EntityModel, EntityRecord, narrowEntity, TypedEntityModel } from './entity';
import { EntityMapping } from './entity-mapping';
import {
  createInvokeEntity,
  createStopEntity,
  createUndoEntity,
  EventEntity,
  InvokeEntity,
  RedoEntity,
  RootEventStreamName,
  StopEntity,
  UndoEntity,
} from './event';
import { EventProcessor } from './event-processor';
import { EventProjector } from './event-projector';
import { Interface, TypedInterfaceModel } from './interface';
import { ProjectionInterface } from './projection-view';
import { AnchorModel, RelatedDataModel, RelatedDataSet } from './related-data-model';
import { SQIORAssistant } from './sqior-assistant';
import {
  BasicSyncMappingFunc,
  EntityMappingFunc,
  EntityMappingOptions,
  SyncMappingFunc,
  SyncMappingOptions,
  TrackingDomainInterface,
} from './tracking-domain-interface';
import { TupleEntity, tupleName } from './tuple';
import {
  diffAgainstDomainData,
  getChangeCommands,
  RelatedDataImportSpec,
} from './related-data-import';
import { EventStreamSystem, SequencedEvent } from '@sqior/js/event-stream';
import { Models } from './models';
import { EventBlackWhiteList } from './event-black-white-list';

type InternalMappingOptions = EntityMappingOptions & {
  trivial?: boolean;
};

function getWeight(options?: EntityMappingOptions) {
  return options && options.weight ? options.weight : 1.0;
}
function weightPred(
  a: [EntityMappingFunc, EntityMappingOptions],
  b: [EntityMappingFunc, EntityMappingOptions]
) {
  return getWeight(a[1]) < getWeight(b[1]);
}

/** Interface of root domain provided to domain during initialization */

export type RootDomainInterface = Domain & {
  eventStreams?: EventStreamSystem;
  eventBlackWhiteList: EventBlackWhiteList;
};

/** Fixed mapping functions */

function Identity(entity: Entity) {
  return entity;
}
function UndefinedEntity() {
  return undefined;
}

/** Base class for all domains  */

export type DomainOptions = {
  entities?: ArraySource<EntityModel>; // Entity models to register
  interfaces?: ArraySource<Interface>; // Interface models to register
  contextProperties?: ArraySource<ContextPropertyModel>; // Context properties to register
  dbType?: string; // Entity type of the database entries
  migrateUntyped?: (obj: ValueObject) => Promise<Entity | undefined>; // Migration conversion function for untyped database entries
  steadyTimer?: TimerInterface; // Steady timer to initialize with
};

export class Domain extends DomainInterface implements Closable {
  constructor(name: string, options: DomainOptions = {}) {
    const models = new Models();
    const contextProperties = new Map<string, ContextPropertyModel>();
    super({
      models,
      entityMapping: new EntityMapping(models, contextProperties, options.steadyTimer),
      contextProperties,
    });
    this.name = name;
    this.theTimer = options.steadyTimer ?? new StdTimer();
    this.dbType = options.dbType;
    this.migrateUntyped = options.migrateUntyped;
    if (!this.dbType && this.migrateUntyped)
      throw new Error(
        'Migration function for untyped database entries provided even if no database type is specified in domain: ' +
          name
      );
    for (const e of ensureArray(options.entities)) this.addEntity(e);
    for (const i of ensureArray(options.interfaces)) this.addInterface(i);
    for (const c of ensureArray(options.contextProperties)) this.addContextProperty(c);
  }

  init(db: DatabaseInterface, mapper: Domain) {
    db;
    mapper;
  }
  init2(db: DatabaseInterface, mapper: Domain) {
    db;
    mapper;
  }
  async asyncInit(db: DatabaseInterface, domain: Domain, manageEvents = true): Promise<void> {
    db;
    domain;
    manageEvents;
  }
  activate(db: DatabaseInterface, mapper: RootDomainInterface, manageEvents = true) {
    db;
    mapper;
    manageEvents;
  }

  async migrate(db: DatabaseInterface, mapper: DomainInterface) {
    /* Check if a database type is specified */
    if (!this.dbType) return;
    /* Use migrate helper */
    return migrateCollection(db, this.name, this.dbType, async (obj) => {
      const ent = obj as Entity;
      try {
        /* Convert if required */
        if (ent.entityType && this.dbType)
          if (mapper.directlyRepresents(ent, this.dbType)) return undefined;
          else return await mapper.tryMap(ent, this.dbType || '');
        if (this.migrateUntyped) {
          const migrated = await this.migrateUntyped(obj);
          return migrated ? await mapper.tryMap(migrated, this.dbType || '') : undefined;
        }
        Logger.warn(
          ['Untyped database value found in migration without a corresponding conversion function'],
          [
            'Untyped database value found in migration without a corresponding conversion function:',
            obj,
          ]
        );
      } catch (e) {
        Logger.warn(
          [
            'Exception when migrating database value of type:',
            ent.entityType,
            '- exception:',
            Logger.exception(e),
          ],
          ['Exception when migrating database value:', obj, '- exception:', Logger.exception(e)]
        );
      }
      return undefined;
    });
  }

  /** Called to give the domain the opportunity to define the database indexes */
  async ensureIndexes(db: DatabaseInterface) {
    /* Nothing to do in the base class */
    db;
  }

  async cleanUp(db: DatabaseInterface, timestamp: ClockTimestamp, sequenceNumber: number) {
    /* Nothing to do in the base class */
    db;
    timestamp;
    sequenceNumber;
  }

  async close() {
    /* Close the entity mapping */
    await this.meta.entityMapping.close();
  }

  get domains(): Domain[] {
    return [this];
  }

  private static AutoMappingWeight = 1;

  addEntity(model: EntityModel, auto = true) {
    /* Register this entity */
    this.meta.models.add(new TypedEntityModel(model));
    /* Automatically add a mapping to the entity interface */
    if (auto)
      this.addTrivialMapping(
        model.type,
        CoreInterfaces.Entity,
        (Domain.AutoMappingWeight += 0.0000001)
      );
    /* If this entity extends another one, automatically add a narrowing mapping */
    const base = model.extends;
    if (auto && base)
      this.addEntityMapping(
        model.type,
        base,
        async (entity, mapper) => {
          return narrowEntity(mapper.meta.models, entity, base);
        },
        { weight: (Domain.AutoMappingWeight += 0.0000001) }
      );
  }

  addInterface(iface: Interface) {
    this.meta.models.add(new TypedInterfaceModel(iface));
  }
  addRelatedDataModel(model: RelatedDataModel) {
    /* Register the interface to determine the related data for an anchor */
    this.addInterface(model.interface);
    /* Register the interface to determine the related data setter for an anchor */
    this.addInterface(model.setterInterface);
    /* Register the interface to determine the anchors have a certain related data value if this related data is indexed */
    if (model.anchorsInterface) this.addInterface(model.anchorsInterface);
  }

  addEntityMapping<FromEntity extends Entity = Entity, ToEntity extends Entity = Entity>(
    from: string,
    to: string,
    mapping: EntityMappingFunc<FromEntity, ToEntity>,
    options: EntityMappingOptions = {}
  ) {
    this.checkSameMapping(from, to, options.weight ?? 1, true, options.context);
    const func = mapping as unknown as EntityMappingFunc;
    this.entityMappings.push({ from, to, func, options });
  }
  addTupleMapping<FromEntities extends Entity[] = Entity[], ToEntity extends Entity = Entity>(
    from: string[],
    to: string,
    mapping: (
      entities: FromEntities,
      mapper: TrackingDomainInterface
    ) => Promise<ToEntity | undefined> | ToEntity | undefined,
    options?: EntityMappingOptions
  ) {
    /* Check if this tuple type is already known */
    const tupleType = tupleName(from);
    this.meta.models.add(
      new TypedEntityModel({
        type: tupleType,
        props: [],
        keys: ['components'],
        extends: CoreEntities.Tuple,
      })
    );
    /* Add mapping */
    this.addEntityMapping(
      tupleType,
      to,
      (entity, mapper) => {
        const tuple = entity as TupleEntity<FromEntities>;
        return mapping(tuple.components, mapper);
      },
      options
    );
  }
  /** Adds a trivial mapping returning the input */
  addTrivialMapping(from: string, to: string, weight = 1) {
    this.checkSameMapping(from, to, weight, false);
    const options = { weight, trivial: true };
    this.basicSyncMappings.push({ from, to, func: Identity, options });
  }
  /** Adds a basic synchronous mapping converting the input to an output */
  addBasicMapping<FromEntity extends Entity = Entity, ToEntity extends Entity = Entity>(
    from: string,
    to: string,
    mapping: BasicSyncMappingFunc<FromEntity, ToEntity>,
    weight = 1
  ) {
    this.checkSameMapping(from, to, weight, false);
    const func = mapping as unknown as BasicSyncMappingFunc;
    const options = { weight, trivial: false };
    this.basicSyncMappings.push({ from, to, func, options });
  }
  /** Prevents the mapping by returning an undefined value */
  preventMapping(from: string, to: string, weight = 1) {
    this.addBasicMapping(from, to, UndefinedEntity, weight);
  }
  /** Synchronous mapping with constant result */
  addConstMapping(from: string, to: string, value: Entity, weight = 1) {
    this.addBasicMapping(
      from,
      to,
      () => {
        return value;
      },
      weight
    );
  }
  /** Synchronous mapping with context properties */
  addSyncMapping<FromEntity extends Entity = Entity, ToEntity extends Entity = Entity>(
    from: string,
    to: string,
    mapping: SyncMappingFunc<FromEntity, ToEntity>,
    options: SyncMappingOptions = {}
  ) {
    this.checkSameMapping(from, to, options.weight ?? 1, false);
    const func = mapping as unknown as SyncMappingFunc;
    this.syncMappings.push({ from, to, func, options });
  }

  addContextProperty(cpm: ContextPropertyModel) {
    this.meta.contextProperties.set(cpm.name, cpm);
  }

  /** Sets related data that changed */
  async setChangedRelatedData(model: AnchorModel, anchor: Entity, data: RelatedDataSet) {
    this.setChangedRelatedDataSet([[model, anchor, data]]);
  }
  /** Sets related data that changed */
  async setChangedRelatedDataSet(data: RelatedDataImportSpec[]) {
    const events = getChangeCommands(await diffAgainstDomainData(data, this));
    if (events.length) await this.invokeEvents(events);
  }

  /* Event processing */

  addEventProcessor<SourceEntity extends Entity = Entity, Output extends ValueObject = ValueObject>(
    filter: Entity,
    processor: (entity: SourceEntity, mapper: DomainInterface) => Promise<Output | undefined>,
    output = this.name + '-stream'
  ) {
    this.addMultiEventProcessor<SourceEntity>(
      filter,
      async (entity, mapper) => {
        const ev = await processor(entity, mapper);
        if (ev) return [[output, ev]];
        else return [];
      },
      [output]
    );
  }
  addInvokeHandler<SourceEntity extends Entity = Entity, Output extends ValueObject = ValueObject>(
    filter: ArraySource<Entity>,
    processor: (
      entity: SourceEntity,
      mapper: DomainInterface,
      seq: number,
      invocation: string,
      user: Entity
    ) => Promise<Output | undefined>,
    output = this.name + '-stream'
  ) {
    const handler = (entity: InvokeEntity, mapper: DomainInterface) => {
      return processor(
        entity.entity as SourceEntity,
        mapper,
        entity.sequenceNumber,
        entity.eventId,
        entity.user
      );
    };
    for (const filt of ensureArray(filter))
      this.addEventProcessor<InvokeEntity>(
        { entityType: CoreEntities.Invoke, entity: filt },
        handler,
        output
      );
  }
  addTrivialInvokeHandler(types: ArraySource<string>) {
    for (const type of ensureArray(types))
      this.addInvokeHandler({ entityType: type }, async (ent) => {
        return ent;
      });
  }
  addUndoHandler<SourceEntity extends Entity = Entity, Output extends ValueObject = ValueObject>(
    filter: Entity,
    processor: (
      entity: SourceEntity,
      invocation: string,
      mapper: DomainInterface,
      seq: number
    ) => Promise<Output | undefined>,
    output = this.name + '-stream'
  ) {
    this.addEventProcessor<UndoEntity>(
      { entityType: CoreEntities.Undo, entity: filter },
      (entity, mapper) => {
        return processor(
          entity.entity as SourceEntity,
          entity.invocation,
          mapper,
          entity.sequenceNumber
        );
      },
      output
    );
  }
  addRedoHandler<SourceEntity extends Entity = Entity, Output extends ValueObject = ValueObject>(
    filter: Entity,
    processor: (
      entity: SourceEntity,
      invocation: string,
      mapper: DomainInterface,
      seq: number
    ) => Promise<Output | undefined>,
    output = this.name + '-stream'
  ) {
    this.addEventProcessor<RedoEntity>(
      { entityType: CoreEntities.Redo, entity: filter },
      (entity, mapper) => {
        return processor(
          entity.entity as SourceEntity,
          entity.invocation,
          mapper,
          entity.sequenceNumber
        );
      },
      output
    );
  }
  addStopHandler<SourceEntity extends Entity = Entity, Output extends ValueObject = ValueObject>(
    filter: Entity,
    processor: (
      entity: SourceEntity,
      invocation: string,
      mapper: DomainInterface,
      seq: number
    ) => Promise<Output | undefined>,
    output = this.name + '-stream'
  ) {
    this.addEventProcessor<StopEntity>(
      { entityType: CoreEntities.Stop, entity: filter },
      (entity, mapper) => {
        return processor(
          entity.entity as SourceEntity,
          entity.invocation,
          mapper,
          entity.sequenceNumber
        );
      },
      output
    );
  }
  addMultiEventProcessor<SourceEntity extends Entity = Entity>(
    filter: Entity,
    processor: (entity: SourceEntity, mapper: DomainInterface) => Promise<[string, ValueObject][]>,
    outputs: string[]
  ) {
    /* It is not permissable to emit events to the root event stream from the processor as it is supposed to be free of side effects */
    for (const o of outputs)
      if (o === RootEventStreamName)
        throw new Error(
          'Event processor is not allowed to write to the root event stream - this is only allowed in the projection stage!'
        );
    this.eventProcessors.push({
      domain: this.name,
      filter: filter,
      processor: (entity, mapper) => {
        return processor(entity as SourceEntity, mapper);
      },
      outputs: outputs,
    });
  }

  /* Event projection */

  /* Registers an event projector (which will be executed outside of a transaction) */
  addEventProjector<SourceType extends ValueObject = ValueObject>(
    projection: (
      db: ProjectionInterface,
      event: SequencedEvent & SourceType,
      mapper: DomainInterface
    ) => Promise<void>,
    input: string = this.name + '-stream',
    output = this.name
  ) {
    if (
      this.eventProjectors.find((ep) => {
        return ep.input === input || ep.output === output;
      })
    )
      throw new Error(
        'Event projector already registered for input and/or output in domain: ' + this.name
      );
    this.eventProjectors.push({
      input: input,
      projection: async (db, event, mapper) => {
        await projection(db, event as SequencedEvent & SourceType, mapper);
        return undefined;
      },
      output: output,
      transaction: false,
    });
  }

  /* Emitting events */

  async emitEvent(ev: EventEntity): Promise<string> {
    await this.eventEmitter(ev);
    return ev.eventId;
  }
  async emitEvents(ev: EventEntity[]): Promise<string[]> {
    await this.eventEmitter(ev);
    return ev.map((e) => {
      return e.eventId;
    });
  }
  invoke(entity: Entity, user: Entity = SQIORAssistant) {
    return this.emitEvent(createInvokeEntity(entity, this.displayTimer.now, user));
  }
  invokeEvents(entities: Entity[], user: Entity = SQIORAssistant) {
    return this.emitEvents(
      entities.map((entity) => {
        return createInvokeEntity(entity, this.displayTimer.now, user);
      })
    );
  }
  undo(entity: Entity, invocation: string, user: Entity = SQIORAssistant) {
    return this.emitEvent(createUndoEntity(entity, invocation, this.displayTimer.now, user));
  }
  stop(entity: Entity, invocation: string, user: Entity = SQIORAssistant) {
    return this.emitEvent(createStopEntity(entity, invocation, this.displayTimer.now, user));
  }

  /* Re-evaluates the provided function every time that it becomes invalid - this can lead to a continuous evaluation if the function access non-cacheable elements.
     This method is provided for convenience in scenarios where multiple mappings need to be observed at the same time. */
  reevaluateMapping(use: (mapper: TrackingDomainInterface) => Promise<void>) {
    return new Reevaluation(async () => {
      const trackingMapper = new TrackingDomainInterface(
        this.meta,
        {},
        [],
        this.mappingTrace || {},
        this.theTimer,
        this.theDisplayTimer
      );
      try {
        await use(trackingMapper);
      } catch (e) {
        Logger.warn(['Exception in Domain.reevaluate():', Logger.exception(e)]);
      }
      return trackingMapper.combinedCacheState();
    });
  }

  /* Internal methods */

  /** Checks if one context is at least as rich as another */
  protected static checkContexts(
    from: string,
    to: string,
    first?: ArraySource<string>,
    second?: ArraySource<string>
  ) {
    for (const key of ensureArray(second))
      if (
        !ensureArray(first).find((prop) => {
          return prop === key;
        })
      )
        throw new Error(
          'Superseeded mapping references additional context property: ' +
            key +
            ' - in mapping from: ' +
            from +
            ' to: ' +
            to
        );
  }

  /** Checks if a mapping with identical weight is already defined */
  protected checkSameMapping(
    from: string,
    to: string,
    weight: number,
    checkContext: boolean,
    context?: string | string[]
  ) {
    if (from === to)
      throw new Error(
        'Source and target type for entity mapping cannot be identical - type provided: ' + from
      );
    /* Check if same mapping with same mapping exists */
    for (const mapping of this.entityMappings)
      if (mapping.from === from && mapping.to === to) {
        if ((mapping.options.weight ?? 1) === weight)
          throw new Error(
            'Mapping with identical weight already defined - types provided: ' +
              from +
              ' and: ' +
              to
          );
        else if ((mapping.options.weight ?? 1) < weight)
          Domain.checkContexts(from, to, mapping.options.context, context);
        else if (checkContext) Domain.checkContexts(from, to, context, mapping.options.context);
      }
    for (const mapping of this.syncMappings)
      if (mapping.from === from && mapping.to === to) {
        if ((mapping.options.weight ?? 1) === weight)
          throw new Error(
            'Mapping with identical weight already defined - types provided: ' +
              from +
              ' and: ' +
              to
          );
        else if (checkContext && (mapping.options.weight ?? 1) > weight)
          Domain.checkContexts(from, to, context, mapping.options.context);
      }
    for (const mapping of this.basicSyncMappings)
      if (mapping.from === from && mapping.to === to && (mapping.options.weight ?? 1) === weight)
        throw new Error(
          'Mapping with identical weight already defined - types provided: ' + from + ' and: ' + to
        );
  }

  /** Registers a mapping function in the order of its weight */
  protected enlistEntityMapping(
    from: string,
    to: string,
    mapping: EntityMappingFunc,
    options: InternalMappingOptions
  ) {
    /* Check if there is an existing mapping, overwrite the current if the weight is less */
    const weight = getWeight(options);
    let exMapping = this.entityMappingOrder.get(from, to);
    if (exMapping) {
      /* Check if a mapping with identical weight is already registered */
      const res = SortedArray.findFirstGreaterOrEqual(exMapping, [mapping, options], weightPred);
      if (res < exMapping.length && weight === getWeight(exMapping[res][1]))
        throw new Error(
          'Entity mapping is already defined with identical weights - types provided: ' +
            from +
            ' -> ' +
            to
        );
      /* Make sure that all superseeding mappings declare all context properties of this mapping */
      for (let i = 0; i < Math.min(res, exMapping.length); i++)
        Domain.checkContexts(from, to, exMapping[i][1].context, options.context);
      for (let i = res; i < exMapping.length; i++)
        Domain.checkContexts(from, to, options.context, exMapping[i][1].context);
      SortedArray.insert(exMapping, [mapping, options], weightPred);
      /* Only update master entry if this is the mapping with the lowest weight */
      if (res) return undefined;
    } else this.entityMappingOrder.set(from, to, (exMapping = [[mapping, options]]));
    return exMapping;
  }

  /** Helper method checking context properties for missing mandatory properties */
  protected validateContext(
    contextProperties: ArraySource<string>,
    context: EntityRecord,
    from: string,
    to: string
  ) {
    /* Check that all mandatory context properties are present, disabled as only required during problem analysis */
    for (const cp of ensureArray(contextProperties)) {
      const cm = this.meta.contextProperties.get(cp);
      if (!cm) throw new Error('Mapping references unknown content property: ' + cp);
      if (cm.mandatory && !context[cp])
        Logger.reportError([
          'Mandatory context property:',
          cp,
          'not found in mapping from:',
          from,
          'to:',
          to,
        ]);
    }
  }

  get steadyTimer(): TimerInterface {
    return this.theTimer;
  }
  set steadyTimer(timer: TimerInterface) {
    this.theTimer = timer;
  }
  get displayTimer(): TimerInterface {
    return this.theDisplayTimer ? this.theDisplayTimer : this.theTimer;
  }
  set displayTimer(timer: TimerInterface) {
    this.theDisplayTimer = timer;
  }

  /** Determines performance metrics for the domain */
  collectMetrics(): PerformanceMetric[] {
    return [];
  }

  readonly name: string;
  /* Mappings */
  private entityMappingOrder = new KeyPairMap<
    string,
    string,
    [EntityMappingFunc, EntityMappingOptions][]
  >();
  readonly basicSyncMappings: {
    from: string;
    to: string;
    func: BasicSyncMappingFunc;
    options: SyncMappingOptions & { trivial: boolean };
  }[] = [];
  readonly syncMappings: {
    from: string;
    to: string;
    func: SyncMappingFunc;
    options: SyncMappingOptions;
  }[] = [];
  readonly entityMappings: {
    from: string;
    to: string;
    func: EntityMappingFunc;
    options: EntityMappingOptions;
  }[] = [];
  /* Eventing */
  eventEmitter: (ev: EventEntity | EventEntity[], db?: CRUDInterface) => Promise<void> =
    async () => {
      throw new Error('No event emitter handler is registered - cannot emit event(s)!');
    };
  readonly eventProcessors: EventProcessor[] = [];
  readonly eventProjectors: EventProjector[] = [];
  protected theTimer: TimerInterface;
  protected theDisplayTimer?: TimerInterface;
  private dbType?: string;
  private migrateUntyped?: (obj: ValueObject) => Promise<Entity | undefined>;
}
